<template>
    <div v-if="1==0"></div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style>

</style>